<template>
  <div>
    <div class="card-toolbar mb-5">
      <router-link v-if="$can('api_internal_configurations.create')"  :to="'/settings/api-internal-configurations/create'"  class="btn btn-primary font-weight-bolder"><v-icon>mdi-plus</v-icon> {{ $t('add_api_internal_configurations') }}</router-link>
    </div>

    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <template slot="generate_token" slot-scope="props">
            <button class="btn btn-outline-primary btn-sm" @click="generate_token(props.row.id)" type="button">
              {{$t('generate_token')}}
            </button>
          </template>
          <template slot="status" slot-scope="props">
            <b-form-checkbox  v-if="$can('api_internal_configurations.change_status')" size="lg" @change="changeStatus(props.row.id, props.row.is_active)" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
            <b-form-checkbox  v-else :disabled="true" size="lg" @change="changeStatus(props.row.id, props.row.is_active)" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
          </template>
          <template slot="actions" slot-scope="props">
            <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('api_internal_configurations.update')"  @click="editItem(props.row.id)">mdi-pencil</v-icon>
            <v-icon small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('api_internal_configurations.delete')"  @click="deleteItem(props.row)">mdi-delete</v-icon>
          </template>
        </v-server-table>


        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  components: {},
  data() {
    return {
      mainRoute: 'settings/api_internal_configurations',
      subMainRoute: 'settings/api_internal_configuration',
      mainRouteDependency: 'base/dependency',
      protocol_list: [
        {id: 1, name: 'http'},
        {id: 2, name: 'https'},
      ],
      status_list: [
        {id: 1, text: this.$t('active')},
        {id: 0, text: this.$t('inactive')},
      ],
      service_provider_list: [],

      columns: ['id', 'title', 'method_name', 'security_key', 'main_link', 'status', 'generate_token' , 'actions'],


      disabledButton: false,
      validation: [],
    }
  },
  methods: {
    getFetch() {
      this.$refs.table.refresh();
    },
    generate_token(id) {
      ApiService.patch(this.subMainRoute + '/generate_token/' + id).then((response) => {
        this.$successAlert(response.data.message);
        this.$refs.table.refresh();
      }).catch((error) => {
        this.$errorAlert(error);
      });
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    actionDelete(item) {
      ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    editItem(id) {
      this.$router.push({name: 'apiInternalConfigurations.edit', params: {id: id}});
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },

    changeStatus(id, status) {
      ApiService.patch(this.subMainRoute + '/change-status/' + id, {
        is_active: (status ? '1' : '0'),
      }).then(response => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
      }).catch(error => {
        this.$errorAlert(error);
      });
    },
    getProviders() {
      ApiService.get(this.mainRouteDependency + "/services-providers").then((response) => {
        this.service_provider_list = response.data.data;
      });
    },

  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          id: '#',
          title: that.$t('title'),
          method_name: that.$t('method_name'),
          security_key: that.$t('security_key'),
          main_link: that.$t('main_link'),
          generate_token: that.$t('generate_token'),
          status: that.$t('status'),
          actions: that.$t('actions'),

        },
        sortable: ['created_at'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: 0,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
          }
          return ApiService.query(that.mainRoute, {..._params})

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },

  mounted() {
    // let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.api_internal_configurations")}]);
    this.getProviders();
  },
};
</script>
